
    .look-detail {
        color: #0200D8;
        font-weight: 500;
        &:hover {
            color: #5864e4;
        }
        &.disabled {
            cursor: not-allowed;
            color: #aaa;
        }
    }
    /deep/.el-dialog__header{
        background: #0200D8;
        span{
            color: #FEFEFE;
            font-weight:400;
            font-size: 16px;
            /*line-height: 30px;*/
        }
    }
    .look-detail + .look-detail {
        margin-left: 10px;
    }
    /deep/.pages-center{
        display: flex;
        justify-content: center;
        margin-top: 24px;
    }
    .analysisReply{
        height: 100%;
        padding: 30px;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
    }
    .el-breadcrumb {
        line-height: 40px;
    }
