
    /deep/.pages-center{
        display: flex;
        justify-content: center;
        margin-top: 24px;
    }
    .optimizeKeyword{
        height: 100%;
        padding: 30px;
    }
